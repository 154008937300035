import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Admins } from '@sleepwell_new_platform/common-types';
import {
  AdminRolesTypeEnum
} from '../utils/Types';

const initialState: Admins[] = [{
  id: '',
  role: AdminRolesTypeEnum.SUPERADMIN,
  name: '',
  phoneNumber: '',
  email: '',
  updatedBy: '',
  createdBy: '',
  createdAt: 0,
  updatedAt: 0,
  isDeleted: 0,
}];

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    setAdmins: (_state: Admins[], action: PayloadAction<Admins[]>) => {
      return action.payload;
    },
    resetAdmins: () => {
      return initialState;
    }
  }
});

export const { setAdmins, resetAdmins } = adminsSlice.actions;
export const adminsReducer = adminsSlice.reducer;
