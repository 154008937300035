import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: any = null;

const loginedSlice = createSlice({
  name: 'logined',
  initialState: initialState,
  reducers: {
    setLogin: (
      _state,
      action: PayloadAction<boolean>
    ) =>  action.payload,
    resetLogin: () => null,
  }
});

export const { setLogin, resetLogin } = loginedSlice.actions;
export const loginedReducer = loginedSlice.reducer;
