export const PaperIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.81157 2H18.0481C18.3475 2 18.5916 2.2838 18.5916 2.63186V21.3681C18.5916 21.7162 18.3475 22 18.0481 22H5.94334C5.64399 22 5.3999 21.7162 5.3999 21.3681V5.9668C5.3999 5.96024 5.40134 5.95395 5.40276 5.9478L5.40276 5.9478C5.40411 5.94191 5.40543 5.93615 5.40543 5.93039C5.40911 5.87256 5.41832 5.81794 5.43398 5.76439C5.43664 5.75645 5.43898 5.74833 5.44133 5.74018L5.44133 5.74018C5.44469 5.72853 5.44807 5.71683 5.4524 5.70549C5.47911 5.63802 5.51227 5.57483 5.55925 5.52021L8.42748 2.18527C8.47446 2.12959 8.52972 2.09103 8.58867 2.05997C8.59751 2.05548 8.60635 2.05213 8.6152 2.04879C8.62109 2.04656 8.62699 2.04434 8.63288 2.04177C8.68078 2.02249 8.72959 2.01071 8.78025 2.0075C8.78533 2.00701 8.79001 2.00539 8.79466 2.00378L8.79467 2.00378C8.80015 2.00188 8.80559 2 8.81157 2ZM8.81157 6.59866H6.48677V20.7363H17.5047V3.26479H9.35501V5.9668C9.35501 6.31486 9.11092 6.59866 8.81157 6.59866ZM7.25587 5.33494H8.26814V4.15797L7.25587 5.33494ZM8.13272 9.0479H15.8587C16.1581 9.0479 16.4012 9.3317 16.4012 9.67976C16.4012 10.0278 16.1571 10.3116 15.8578 10.3116H8.13272C7.83337 10.3116 7.58929 10.0278 7.58929 9.67976C7.58929 9.3317 7.83337 9.0479 8.13272 9.0479ZM15.8587 12.8678H8.13272C7.83337 12.8678 7.58929 13.1516 7.58929 13.4997C7.58929 13.8477 7.83337 14.1315 8.13272 14.1315H15.8578C16.1571 14.1315 16.4012 13.8477 16.4012 13.4997C16.4012 13.1516 16.1581 12.8678 15.8587 12.8678ZM8.13272 16.6882H13.055C13.3543 16.6882 13.5975 16.972 13.5975 17.32C13.5975 17.6681 13.3534 17.9519 13.054 17.9519H8.13272C7.83337 17.9519 7.58929 17.6681 7.58929 17.32C7.58929 16.972 7.83337 16.6882 8.13272 16.6882Z'
        fill='#4B4B4B'
      />
    </svg>
  );
};
