export const SearchIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 17C16.866 17 20 13.866 20 10C20 6.13401 16.866 3 13 3C9.13401 3 6 6.13401 6 10C6 13.866 9.13401 17 13 17Z'
        stroke='#4B4B4B'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M8 15L2 21'
        stroke='#4B4B4B'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
    </svg>
  );
};
