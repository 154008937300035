import { styled } from '@mui/system';
import { AccountIcon } from '../atoms/icons/index';
import { Logout } from '../atoms/icons/Logout';
import { HeaderButton } from '../atoms/HeaderButton';
import { useNavigate } from 'react-router-dom';

type Props = {
  username: string;
};

export const Header = ({ username }: Props) => {
  const navigation = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigation('/login')
  };
  return (
    <Container>
      <TitleContainer>
        <TitleText bold>スリープウェル</TitleText>
        <TitleText>{username}</TitleText>
      </TitleContainer>
      <Menu>
        <HeaderButton
          onClick={()=>navigation('/reset-password')}
        >
          <AccountIcon />
        </HeaderButton>
        <HeaderButton onClick={logout}>
          <Logout />
        </HeaderButton>
      </Menu>
    </Container>
  );
};

const Container = styled('div')({
  backgroundColor: '#4B4B4B',
  boxSizing: 'border-box',
  color: '#FFF',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '72px',
});

const TitleContainer = styled('div')({
  padding: '22px 20px',
});

const TitleText = styled('div')<{ bold?: true }>(({ bold }) => ({
  fontFamily: 'YuGothic',
  fontSize: '20px',
  fontWeight: bold ? 'bold' : 'normal',
}));

const Menu = styled('div')({
  display: 'flex',
});
