import { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IsLogined from '../../utils/IsLogined';
import { Progress } from '../atoms/Progress';
import useLocalStorage from '../../hooks/localstorage';

const LoginPage = lazy(() => import('./LoginPage'));
const AdminsPage = lazy(() => import('./AdminsPage'));
const AnalyzationRequestPage = lazy(() => import('./AnalyzationReuestPage'));
const AnalyzationsPage = lazy(() => import('./AnalyzationsPage'));
const ClinicsPage = lazy(() => import('./ClinicsPage'));
const CreateClinicPage = lazy(() => import('./CreateClinicPage'));
const EditClinicPage = lazy(() => import('./EditClinicPage'));
const ClinicUsersPage = lazy(() => import('./ClinicUsersPage'));
const MedicalPartnersPage = lazy(() => import('.//MedicalPartnersPage'));
const DocumentsPage = lazy(() => import('./DocumentsPage'));
const EquipmentRentals = lazy(() => import('./EquipmentRental'));
const AdminSettingsPage = lazy(() => import('./AdminSettingsPage'));
const ModulesPage = lazy(() => import('./ModulesPage'));
const AnnouncementsPage = lazy(() => import('./AnnouncementsPage'));
const CreateAnnouncementsPage = lazy(() => import('./CreateAnnouncementsPage'));
const EditAnnouncementsPage = lazy(() => import('./EditAnnouncementsPage'));
const ModuleVersionPage = lazy(() => import('./ModuleVersionPage'));
const AnonymousProcessedAnalyzationPage = lazy(() => import('./AnonymousProcessedAnalyzationPage'));
const ChangePasswordPage = lazy(() => import('./ChangePasswordPage'));
const CountPage = lazy(() => import('./CountPage'));
const DashboardPage = lazy(() => import('./DashboardPage'));
const NotFoundPage = lazy(() => import('./NotFoundPage'));

const pages = [
  {
    path: '/',
    component: (
      <IsLogined>
        <DashboardPage />
      </IsLogined>)
  },
  {
    path: '/login',
    component: <LoginPage />,
  },
  {
    path: '/admins',
    component:
      (
        <IsLogined>
          <AdminsPage />
        </IsLogined>
      ),
  },
  {
    path: '/clinics',
    component:
      (
        <IsLogined>
          <ClinicsPage />
        </IsLogined>
      ),
  },
  {
    path: '/clinics/create',
    component:
      (
        <IsLogined>
          <CreateClinicPage />
        </IsLogined>
      ),
  },
  {
    path: '/clinics/edit/:id',
    component:
      (
        <IsLogined>
          <EditClinicPage />
        </IsLogined>
      ),
  },
  {
    path: '/clinicUsers',
    component:
      (
        <IsLogined>
          <ClinicUsersPage />
        </IsLogined>
      ),
  },
  {
    path: '/analyzation-request/:id',
    component:
      (<IsLogined>
        <AnalyzationRequestPage />
      </IsLogined>
      ),
  },
  {
    path: '/analyzations',
    component:
      (<IsLogined>
        <AnalyzationsPage />
      </IsLogined>
      ),
  },
  {
    path: "/medicalPartners",
    component: (
      <IsLogined>
        <MedicalPartnersPage />
      </IsLogined>
    )
  },
  {
    path: '/documents',
    component:
      (<IsLogined>
        <DocumentsPage />
      </IsLogined>
      ),
  },
  {
    path: "/equipments",
    component: (
      <IsLogined>
        <EquipmentRentals />
      </IsLogined>
    )
  },
  {
    path: "/adminSettings",
    component: (
      <IsLogined>
        <AdminSettingsPage />
      </IsLogined>
    )
  },
  {
    path: '/modules',
    component:
      (
        <IsLogined>
          <ModulesPage />
        </IsLogined>
      ),
  },
  {
    path: '/announcements',
    component:
      (
        <IsLogined>
          <AnnouncementsPage />
        </IsLogined>
      ),
  },
  {
    path: '/announcements/create',
    component:
      (
        <IsLogined>
          <CreateAnnouncementsPage />
        </IsLogined>
      ),
  },
  {
    path: '/announcements/edit',
    component:
      (
        <IsLogined>
          <EditAnnouncementsPage />
        </IsLogined>
      ),
  },
  {
    path: '/modules/:id/versions',
    component:
      (<IsLogined>
        <ModuleVersionPage />
      </IsLogined>
      ),
  },
  {
    path: "/reset-password",
    component: (
      <IsLogined>
        <ChangePasswordPage />
      </IsLogined>
    )
  },
  {
    path: "/analyzation-count",
    component: (
      <IsLogined>
        <CountPage />
      </IsLogined>
    )
  },
];

const anonymousesPages = [
  {
    path: '/',
    component: (
      <IsLogined>
        <AnonymousProcessedAnalyzationPage />
      </IsLogined>)
  },
  {
    path: '/login',
    component: <LoginPage />,
  },
  {
    path: '/anonymousProcessedAnalyzation',
    component:
      (<IsLogined>
        <AnonymousProcessedAnalyzationPage />
      </IsLogined>
      ),
  },
  {
    path: "/reset-password",
    component: (
      <IsLogined>
        <ChangePasswordPage />
      </IsLogined>
    )
  },
]

type RouteType = {
  path: string;
  component: JSX.Element;
};

export const Pages = () => {

  const [group] = useLocalStorage('group', '');

  // State for the routes
  const [currentRoutes, setCurrentRoutes] = useState<RouteType[]>([]);

  // Update routes when group changes
  useEffect(() => {
    setCurrentRoutes(group === 'anonymous' ? anonymousesPages : pages);
  }, [group]);

  return (
    <BrowserRouter>
      <Suspense fallback={<Progress />}>
        <Routes>
          {currentRoutes.map((item, index) => (
            <Route key={index} path={item.path} element={item.component} />
          ))}
         <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
