import { useEffect, useCallback } from 'react';
import { useAppDispatch, useTypedSelector } from '../redux';
import { setLogin, resetLogin } from '../redux/logined';
import { setIdToken, resetIdToken } from '../redux/idToken';
import axios from 'axios';
import { SleepwellApi } from '@sleepwell_new_platform/common-functions/lib/api';
import { useNavigate } from 'react-router-dom';
const api = new SleepwellApi(axios);

//import { AdminsRedux } from '../redux/admins/index';

export const AwsResourcesToRedux = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logined = useTypedSelector((state) => state.logined);

  const setSession = useCallback(
    async (idToken: string) => {
      dispatch(setIdToken(idToken));
      dispatch(setLogin(true));
    },
    [dispatch]
  );

  const observeAuth = useCallback(async () => {
    const name = localStorage?.name;
    const idToken = localStorage?.idToken;
    const refreshToken = localStorage?.refreshToken;

    if (!idToken) {
      dispatch(resetIdToken());
      dispatch(setLogin(false));
      return;
    }
    const isIdToken = await api.isIdTokenEnable(idToken).catch((err: any) => {
      if (err?.request?.status === 400) {
        navigate('/login');
      }
      return err;
    });
    if (isIdToken) {
      // idToken が有効の場合
      setSession(idToken);
    } else {
      // idTokenが無効の場合、refreshTokenで有効なidTokenを取得する
      const refreshTokenRusult = await api.refreshIdToken(name, refreshToken).catch((err: Error) => {
        console.log(err)
        //return err;
      });
      console.log(refreshTokenRusult)
      if (refreshTokenRusult.status === 200) {
        // 最新のidToken をローカルストレージへ再格納
        localStorage.idToken = refreshTokenRusult.data.idToken;
        setSession(refreshTokenRusult.data.idToken);
      } else {
        // refreshToken も（有効期限切れなどで）無効の場合、ログアウト状態にする
        dispatch(resetIdToken());
        dispatch(resetLogin());
        localStorage.clear();
        navigate('/login');
      }
    } // eslint-disable-next-line
  }, [dispatch, setSession]);

  useEffect(() => {
    observeAuth();
  }, [observeAuth]);

  return (
    <>
      {logined ? (
        <>
          {/*<AdminsRedux />*/}
        </>
      ) : null}
    </>
  );
};
