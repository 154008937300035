import { createTheme, ThemeProvider } from "@mui/material";
import type {} from "@mui/x-data-grid/themeAugmentation";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0077C7",
    },
    grey: {
      500: "#898989"
    },
    text: {
      primary: "#4B4B4B",
      secondary: "#FFFFFF"
    }
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: ({ _, theme }) => ({
          ...({
            backgroundColor: theme.palette.grey[500],
            color: theme.palette.text.secondary
          }),
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ _, theme }) => ({
          ...({
            color: theme.palette.grey[400],
          }),
        }),
      },
    },
  },
});

type ThemeProviderWrapProps = React.PropsWithChildren<{}>;

export const ThemeProviderWrap: React.FC<ThemeProviderWrapProps> = ({
  children,
}) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;
