import { useEffect, useState } from "react";

function useLocalStorage(key: string, initialValue: string = ""): [string, (value: string) => void] {
  const [value, setValue] = useState<string>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? storedValue : initialValue;
  });

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key === key) {
        setValue(event.newValue ?? initialValue);
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [key, initialValue]);

  const updateValue = (newValue: string) => {
    localStorage.setItem(key, newValue);
    setValue(newValue);
  };

  return [value, updateValue];
}

export default useLocalStorage;
