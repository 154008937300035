import { ReactNode } from 'react';
import { styled } from '@mui/system';

type Props = {
  icon: ReactNode;
  isActive: boolean;
  children: ReactNode;
  onClick?: () => void;
};

export const SidebarMenuButton = ({ icon, isActive, children, onClick }: Props) => {
  return (
    <Container onClick={onClick} isActive={isActive}>
      <div>{icon}</div>
      <MenuText>{children}</MenuText>
    </Container>
  );
};

const Container = styled('div')<{ isActive: boolean }>(({ isActive }) => ({
  backgroundColor: isActive ? '#e2e2e2' : 'inherit',
  fontWeight: isActive ? 'bold' : 'normal',
  display: 'flex',
  padding: '12px 8px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: '#e2e2e2',
    fontWeight: 'bold',
  },
}));
const MenuText = styled('div')({
  fontSize: '14px',
  marginLeft: '8px',
});
