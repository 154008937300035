import { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
//import { logout } from '../amplify/auth';
import { AwsResourcesToRedux } from '../aws/awsResourcesToRedux';
import { Progress } from '../components/atoms/Progress';
import { PageFrame } from '../components/organisms/PageFrame';
import { useTypedSelector } from '../redux';

type Props = {
  children: ReactNode;
}

const IsLogined = (props: Props) => {
  const logined = useTypedSelector(state => state.logined);
  const idToken = useTypedSelector(state => state.idToken);
  //const admin = useTypedSelector(state => state.admin);
  const [loadingAdmin, setLoadingAdmin] = useState(true);
  useEffect(() => {
    if (logined) {
      //loading判定にadminIdを使用
      //if (admin.adminId) {
        setLoadingAdmin(false);
      //}
    }
  }, [logined, idToken/*admin*/]);

  if (logined === false) {
    return <Navigate to='/login' />
  };
  if (!localStorage?.idToken) {
    return <Navigate to='/login' />
  };

  if (loadingAdmin) {
    return (
      <>
        <AwsResourcesToRedux />
        <Progress width='100%'/>
      </>
    );
  } else {
    return (
      <PageFrame username=''>
        <AwsResourcesToRedux />
        {props.children}
      </PageFrame>
    )
  }
}
export default IsLogined;
