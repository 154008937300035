import { ReactNode } from 'react';
import styled from '@emotion/styled';

type Props = {
  children: ReactNode;
  onClick: any;
};

export const HeaderButton = ({ children, onClick }: Props) => {
  return <Container onClick={onClick}>{children}</Container>;
};

const Container = styled.div`
  background-color: inherit;
  border-left: 1px solid #fff;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
  padding-bottom: 16px;
  width: 100px;
`;
