import { Pages } from './components/pages';
import { store } from './redux';
import { Provider } from 'react-redux';
import { ThemeProviderWrap } from './provider/ThemeProvider';

const App = () => {
  return (
    <>
      <ThemeProviderWrap>
        <Provider store={store}>
          <Pages />
        </Provider>
      </ThemeProviderWrap>
    </>
  );
};

export default App;
