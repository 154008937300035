/*import { 
  RoleTypeEnum,
  PriorityTypeEnum,
  CategoryTypeEnum,
  StatusTypeEnum,
  PrefectureTypeEnum,
  AnalyzeServerTypeEnum,
  ReportLangTypeEnum,
  ReportTypeEnum,
  InputPatientBirthDayTypeEnum,
  InputPatientAddressTypeEnum
 } from '@sleepwell_new_platform/common-types';*/
//import { ManIcon, WomanIcon } from '../icons/SvgIcon';

import { ClinicRoleTypeDisplayName } from "@sleepwell_new_platform/common-types";
import { ManIcon, WomanIcon } from '../components/atoms/icons/SvgIcon';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export enum StatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export enum SpecializedMedicalListEnum {
  A = "a",
  B = "b",
  C = "c",
  D = "d"
}

export enum AdminRolesTypeEnum {
  SUPERADMIN = "superAdmin",
  ADMIN = "admin",
  ANONYMOUS = "anonymous"
}

export enum ClinicRoleTypeEnum {
  OWNER = "owner",
  MANAGER = "manaager",
  DOCTOR = "doctor",
  COMEDICAL = "coMedical",
  STAFF = "staff",
}

export enum PriorityTypeEnum {
  NORMAL = "normal",
  HIGH = "high",
}

export enum CategoryTypeEnum {
  CLINIC = "医療機関",
  ENTERPRISE = "研究機関・企業",
  HEALTHCHECKFACILITY = "健診施設",
  OTHER = "その他"
}

export enum StatusTypeEnum {
  ACTIVE = "active",
  INACTIVE = "inActive",
}

export enum PrefectureTypeEnum {
  HOKKAIDOU = 0,
  AOMORI = 1,
  IWATE = 2,
  MIYAGI = 3,
  AKITA = 4,
  YAMAGATA = 5,
  FUKUSHIMA = 6,
  IBARAKI = 7,
  TOCHIGI = 8,
  GUNMA = 9,
  SAITAMA = 10,
  CHIBA = 11,
  TOKYO = 12,
  KANAGAWA = 13,
  NIGATA = 14,
  TOYAMA = 15,
  ISHIKAWA = 16,
  FUKUI = 17,
  YAMANASHI = 18,
  NAGANO = 19,
  GIFU = 20,
  SHIZUOKA = 21,
  AICHI = 22,
  MIE = 23,
  SHIGA = 24,
  KYOTO = 25,
  OSAKA = 26,
  HYOGO = 27,
  NARA = 28,
  WAKAYAMA = 29,
  TOTTORI = 30,
  SHIMANE = 31,
  OKAYAMA = 32,
  HIROSHIMA = 33,
  YAMAGUCHI = 34,
  TOKUSHIMA = 35,
  KAGAWA = 36,
  EHIME = 37,
  KOUCHI = 38,
  FUKUOKA = 39,
  SAGA = 40,
  NAGASAKI = 41,
  KUMAMOTO = 42,
  OOITA = 43,
  MIYAZAKI = 44,
  KAGOSHIMA = 45,
  OKINAWA = 46,
}

export enum AnalyzeServerTypeEnum {
  DEFAULTANALYZE = '通常解析用'
}

export enum ReportLangTypeEnum {
  JA = 'ja',
  EN = 'en'
}

export enum ReportTypeEnum {
  A3_FOR_DOCK_AND_MEDICAL_INSTITUTIONS = 'doctorA3',
  A4_FOR_DOC_AND_MEDICAL_INSTITUTIONS = 'doctorA4',
  DETAILED_VERSION_OF_SLEEP_VARIABLES = 'research'
}

export enum InputPatientBirthDayTypeEnum {
  YYYYMMDD = 'yyyymmdd',
  YYYYMM = 'yyyymm',
  YYYY = 'yyyy'
}

export enum InputPatientAddressTypeEnum {
  PREFECTURE = 'prefecture',
  AREA = 'area',
  NONE = 'none'
}

export type AdminsState = {
  id: string,
  role: AdminRolesTypeEnum,
  name: string,
  phoneNumber: string,
  email: string,
  updatedBy: string,
  createdBy: string,
  createdAt: number,
  updatedAt: number,
  isDeleted: number,
}

export type AdminsUpdateParams = {
  tableName: string,
  id: string,
  role?: string,
  name: string,
  email: string,
  phoneNumber: string,
  removeRole?: string,
}

export const roleTypes = [
  {
    value: AdminRolesTypeEnum.SUPERADMIN,
    title: '特権システム管理者',
  },
  {
    value: AdminRolesTypeEnum.ADMIN,
    title: 'システム管理者',
  },
  {
    value: AdminRolesTypeEnum.ANONYMOUS,
    title: '匿名加工閲覧ユーザ',
  },
];

export const priorityTypes = [
  {
    value: PriorityTypeEnum.NORMAL,
    title: 'normal',
  },
  {
    value: PriorityTypeEnum.HIGH,
    title: 'high',
  }
];

export const categoryList = [
  {
    value: CategoryTypeEnum.CLINIC,
    label: '医療機関',
  },
  {
    value: CategoryTypeEnum.ENTERPRISE,
    label: '研究機関・企業',
  },
  {
    value: CategoryTypeEnum.HEALTHCHECKFACILITY,
    label: '健診施設',
  },
  {
    value: CategoryTypeEnum.OTHER,
    label: 'その他',
  }
];

export const statusList = [
  {
    value: StatusTypeEnum.ACTIVE,
    label: '有効',
  },
  {
    value: StatusTypeEnum.INACTIVE,
    label: '無効',
  }
];

export const prefectureList = [
  { value: PrefectureTypeEnum.HOKKAIDOU, label: '' },
  { value: PrefectureTypeEnum.AOMORI, label: '青森県' },
  { value: PrefectureTypeEnum.IWATE, label: '岩手県' },
  { value: PrefectureTypeEnum.MIYAGI, label: '宮城県' },
  { value: PrefectureTypeEnum.AKITA, label: '秋田県' },
  { value: PrefectureTypeEnum.YAMAGATA, label: '山形県' },
  { value: PrefectureTypeEnum.FUKUSHIMA, label: '福島県' },
  { value: PrefectureTypeEnum.IBARAKI, label: '茨城県' },
  { value: PrefectureTypeEnum.TOCHIGI, label: '栃木県' },
  { value: PrefectureTypeEnum.GUNMA, label: '群馬県' },
  { value: PrefectureTypeEnum.SAITAMA, label: '埼玉県' },
  { value: PrefectureTypeEnum.CHIBA, label: '千葉県' },
  { value: PrefectureTypeEnum.TOKYO, label: '東京都' },
  { value: PrefectureTypeEnum.KANAGAWA, label: '神奈川県' },
  { value: PrefectureTypeEnum.NIGATA, label: '新潟県' },
  { value: PrefectureTypeEnum.TOYAMA, label: '富山県' },
  { value: PrefectureTypeEnum.ISHIKAWA, label: '石川県' },
  { value: PrefectureTypeEnum.FUKUI, label: '福井県' },
  { value: PrefectureTypeEnum.YAMANASHI, label: '山梨県' },
  { value: PrefectureTypeEnum.NAGANO, label: '長野県' },
  { value: PrefectureTypeEnum.GIFU, label: '岐阜県' },
  { value: PrefectureTypeEnum.SHIZUOKA, label: '静岡県' },
  { value: PrefectureTypeEnum.AICHI, label: '愛知県' },
  { value: PrefectureTypeEnum.MIE, label: '三重県' },
  { value: PrefectureTypeEnum.SHIGA, label: '滋賀県' },
  { value: PrefectureTypeEnum.KYOTO, label: '京都府' },
  { value: PrefectureTypeEnum.OSAKA, label: '大阪府' },
  { value: PrefectureTypeEnum.HYOGO, label: '兵庫県' },
  { value: PrefectureTypeEnum.NARA, label: '奈良県' },
  { value: PrefectureTypeEnum.WAKAYAMA, label: '和歌山県' },
  { value: PrefectureTypeEnum.TOTTORI, label: '鳥取県' },
  { value: PrefectureTypeEnum.SHIMANE, label: '島根県' },
  { value: PrefectureTypeEnum.OKAYAMA, label: '岡山県' },
  { value: PrefectureTypeEnum.HIROSHIMA, label: '広島県' },
  { value: PrefectureTypeEnum.YAMAGUCHI, label: '山口県' },
  { value: PrefectureTypeEnum.TOKUSHIMA, label: '徳島県' },
  { value: PrefectureTypeEnum.KAGAWA, label: '香川県' },
  { value: PrefectureTypeEnum.EHIME, label: '愛媛県' },
  { value: PrefectureTypeEnum.KOUCHI, label: '高知県' },
  { value: PrefectureTypeEnum.FUKUOKA, label: '福岡県' },
  { value: PrefectureTypeEnum.SAGA, label: '佐賀県' },
  { value: PrefectureTypeEnum.NAGASAKI, label: '長崎県' },
  { value: PrefectureTypeEnum.KUMAMOTO, label: '熊本県' },
  { value: PrefectureTypeEnum.OOITA, label: '大分県' },
  { value: PrefectureTypeEnum.MIYAZAKI, label: '宮崎県' },
  { value: PrefectureTypeEnum.KAGOSHIMA, label: '鹿児島県' },
  { value: PrefectureTypeEnum.OKINAWA, label: '沖縄県' },
];

export const analyizeServerList = [
  { value: AnalyzeServerTypeEnum.DEFAULTANALYZE, label: '通常解析用' }
];

export const reportLangTypes = [
  { value: ReportLangTypeEnum.JA, title: '日本語' },
  { value: ReportLangTypeEnum.EN, title: '英語' }
];

export const reportTypes = [
  { value: ReportTypeEnum.A3_FOR_DOCK_AND_MEDICAL_INSTITUTIONS, title: 'ドック・医療機関向けA3' },
  { value: ReportTypeEnum.A4_FOR_DOC_AND_MEDICAL_INSTITUTIONS, title: 'ドック・医療機関向けA4' },
  { value: ReportTypeEnum.DETAILED_VERSION_OF_SLEEP_VARIABLES, title: '睡眠変数詳細版（研究者向け）' },
];

export const inputPatientBirthDayTypes = [
  { value: InputPatientBirthDayTypeEnum.YYYYMMDD, title: '年月日を入力' },
  { value: InputPatientBirthDayTypeEnum.YYYYMM, title: '年月のみ入力（入力した年月の15日生まれとして扱います）' },
  { value: InputPatientBirthDayTypeEnum.YYYY, title: '年のみ入力（入力した年の7月1日生まれとして扱います）' },
];

export const inputPatientAddressTypes = [
  { value: InputPatientAddressTypeEnum.PREFECTURE, title: '都道府県を入力' },
  { value: InputPatientAddressTypeEnum.AREA, title: '地域（関東、関西など）まで入力' },
  { value: InputPatientAddressTypeEnum.NONE, title: '入力なし' },
];

export const typeNameMap = new Map([
  [AdminRolesTypeEnum.SUPERADMIN, '特権システム管理者'],
  [AdminRolesTypeEnum.ADMIN, 'システム管理者'],
  [AdminRolesTypeEnum.ANONYMOUS, '匿名加工閲覧ユーザ'],
]);

export type TypeDisplayName = '特権システム管理者' | 'システム管理者' | '匿名加工閲覧ユーザ';
export const convertName2RoleType = (
  typeDisplayName: TypeDisplayName
): AdminRolesTypeEnum => {
  switch (typeDisplayName) {
    case '特権システム管理者':
      return AdminRolesTypeEnum.SUPERADMIN;
    case 'システム管理者':
      return AdminRolesTypeEnum.ADMIN;
    case '匿名加工閲覧ユーザ':
      return AdminRolesTypeEnum.ANONYMOUS;
  }
};

export const convertRoleType2Name = (
  type: AdminRolesTypeEnum | null | undefined
): TypeDisplayName => {
  switch (type) {
    case AdminRolesTypeEnum.SUPERADMIN:
      return '特権システム管理者';
    case AdminRolesTypeEnum.ADMIN:
      return 'システム管理者';
    case AdminRolesTypeEnum.ANONYMOUS:
      return '匿名加工閲覧ユーザ';
    default:
      return '匿名加工閲覧ユーザ';
  }
};

export const convertName2ClinicRoleType = (
  typeDisplayName: ClinicRoleTypeDisplayName
): ClinicRoleTypeEnum => {
  switch (typeDisplayName) {
    case '管理者':
      return ClinicRoleTypeEnum.OWNER;
    case '運用管理者（事務長）':
      return ClinicRoleTypeEnum.MANAGER;
    case '主担当':
      return ClinicRoleTypeEnum.DOCTOR;
    case '補助者':
      return ClinicRoleTypeEnum.COMEDICAL;
    case 'スタッフ':
      return ClinicRoleTypeEnum.STAFF;
    default:
      return ClinicRoleTypeEnum.OWNER;
  }
};

export const convertClinicRoleTypeC2Name = (
  type: ClinicRoleTypeEnum
): ClinicRoleTypeDisplayName => {
  switch (type) {
    case ClinicRoleTypeEnum.OWNER:
      return '管理者';
    case ClinicRoleTypeEnum.DOCTOR:
      return '主担当';
    case ClinicRoleTypeEnum.COMEDICAL:
      return '補助者';
    case ClinicRoleTypeEnum.STAFF:
      return 'スタッフ';
    default:
      return '管理者';
  }
};

export const convertPrefectureType2Name = (
  type: PrefectureTypeEnum | null | undefined
): any => {
  switch (type) {
    case PrefectureTypeEnum.HOKKAIDOU:
      return '北海道';
    case PrefectureTypeEnum.AOMORI:
      return '青森県';
    case PrefectureTypeEnum.IWATE:
      return '岩手県';
    case PrefectureTypeEnum.MIYAGI:
      return '宮城県';
    case PrefectureTypeEnum.AKITA:
      return '秋田県';
    case PrefectureTypeEnum.YAMAGATA:
      return '山形県';
    case PrefectureTypeEnum.FUKUSHIMA:
      return '福島県';
    case PrefectureTypeEnum.IBARAKI:
      return '茨城県';
    case PrefectureTypeEnum.TOCHIGI:
      return '栃木県';
    case PrefectureTypeEnum.GUNMA:
      return '群馬県';
    case PrefectureTypeEnum.SAITAMA:
      return '埼玉県';
    case PrefectureTypeEnum.CHIBA:
      return '千葉県';
    case PrefectureTypeEnum.TOKYO:
      return '東京都';
    case PrefectureTypeEnum.KANAGAWA:
      return '神奈川県';
    case PrefectureTypeEnum.NIGATA:
      return '新潟県';
    case PrefectureTypeEnum.TOYAMA:
      return '富山県';
    case PrefectureTypeEnum.ISHIKAWA:
      return '石川県';
    case PrefectureTypeEnum.FUKUI:
      return '福井県';
    case PrefectureTypeEnum.YAMANASHI:
      return '山梨県';
    case PrefectureTypeEnum.NAGANO:
      return '長野県';
    case PrefectureTypeEnum.GIFU:
      return '岐阜県';
    case PrefectureTypeEnum.SHIZUOKA:
      return '静岡県';
    case PrefectureTypeEnum.AICHI:
      return '愛知県';
    case PrefectureTypeEnum.MIE:
      return '三重県';
    case PrefectureTypeEnum.SHIGA:
      return '滋賀県';
    case PrefectureTypeEnum.KYOTO:
      return '京都府';
    case PrefectureTypeEnum.OSAKA:
      return '大阪府';
    case PrefectureTypeEnum.HYOGO:
      return '兵庫県';
    case PrefectureTypeEnum.NARA:
      return '奈良県';
    case PrefectureTypeEnum.WAKAYAMA:
      return '和歌山県';
    case PrefectureTypeEnum.TOTTORI:
      return '鳥取県';
    case PrefectureTypeEnum.SHIMANE:
      return '島根県';
    case PrefectureTypeEnum.OKAYAMA:
      return '岡山県';
    case PrefectureTypeEnum.HIROSHIMA:
      return '広島県';
    case PrefectureTypeEnum.YAMAGUCHI:
      return '山口県';
    case PrefectureTypeEnum.TOKUSHIMA:
      return '徳島県';
    case PrefectureTypeEnum.KAGAWA:
      return '香川県';
    case PrefectureTypeEnum.EHIME:
      return '愛媛県';
    case PrefectureTypeEnum.KOUCHI:
      return '高知県';
    case PrefectureTypeEnum.FUKUOKA:
      return '福岡県';
    case PrefectureTypeEnum.SAGA:
      return '佐賀県';
    case PrefectureTypeEnum.NAGASAKI:
      return '長崎県';
    case PrefectureTypeEnum.KUMAMOTO:
      return '熊本県';
    case PrefectureTypeEnum.OOITA:
      return '大分県';
    case PrefectureTypeEnum.MIYAZAKI:
      return '宮崎県';
    case PrefectureTypeEnum.KAGOSHIMA:
      return '鹿児島県';
    case PrefectureTypeEnum.OKINAWA:
      return '沖縄県';
    default:
      return '';
  }
};

export const convertGenderTypeToName = (genderType: string | undefined, lang?: 'ja' | 'en') => {
  if(lang === 'ja') {
    return genderType === '男性'
    ? '男性'
    : genderType === '女性'
    ? '女性'
    : genderType === '答えたくない'
    ? '答えたくない'
    : null;
  } else {
    return genderType === '男性'
    ? 'man'
    : genderType === '女性'
    ? 'woman'
    : genderType === '答えたくない'
    ? 'no answer'
    : null;
  }
};

/*export const convertGenderTypeToIcon = (genderType: string | undefined) => {
  return genderType === '男性' ? (
    <ManIcon />
  ) : genderType === '女性' ? (
    <WomanIcon />
  ) : genderType === '答えたくない' ? (
    <AccountCircleOutlinedIcon
      style={{ width: '50px', height: '50px', color: '#c0c0c0' }}
    />
  ) : null;
};*/

export const convertGenderTypeToIcon = (genderType: string | undefined) => {
  return genderType === '男性' ? (
    <ManIcon />
  ) : genderType === '女性' ? (
    <WomanIcon />
  ) : genderType === '答えたくない' ? (
    <AccountCircleOutlinedIcon
      style={{ width: '50px', height: '50px', color: '#c0c0c0' }}
    />
  ) : null;
};

export const convertGenderType = (genderType: string | undefined) => {
  switch (genderType) {
    case "男性":
      return "man";
    case "女性":
        return "woman";
    default:
      break;
  }
};

