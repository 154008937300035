import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: boolean = false;

const isPrintingSlice = createSlice({
  name: 'isPrinting',
  initialState: initialState,
  reducers: {
    setIsPrinting: (_state, action: PayloadAction<boolean>) => {
      console.log("setIsPrinting",action)
      return action.payload
    },
  },
});

export const { setIsPrinting } = isPrintingSlice.actions;
export const isPrintingReducer = isPrintingSlice.reducer;
