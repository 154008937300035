import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';

type progressType = {
  message?: string;
  width?: string;
}

export const Progress = (props: progressType) => {
  return (
    <Box sx={{
      display: 'flex',
      width: props.width || 'calc(100% - 350px)',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '& > * + *': {
        //marginLeft: props.theme.spacing(2),
      },
      position: 'absolute',
      top: 250,
      marginTop: ' 2vw',
    }}>
      <Message>{props.message}</Message>
      <CircularProgress
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          '& > * + *': {
            //marginLeft: props.theme.spacing(2),
          },
          color: '#529BC5'
        }}
        size={55}
        thickness={5.5} />
    </Box>
  );
};

export const PdfProgress = (props: progressType) => {
  return (
    <>
      {/* 透明なオーバーレイ要素 */}
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // 透明度を指定（ここでは半透明）
          zIndex: 9999, // オーバーレイが最前面に表示されるようにする
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', // テキストを中央に配置
        }}
      >
        {/* Loading... テキスト */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center', // テキストとアイコンを中央に配置
            justifyContent: 'center', // コンテンツ全体を中央に配置
            backgroundColor: 'white', // 背景色を白に設定
            padding: '10px 20px', // パディングを追加してテキストを囲む
            borderRadius: '5px', // 角丸にする
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)', // 少し影を追加
            fontSize: '22px',
            height: '30px',
            textAlign: 'center'
          }}
        >
          <CircularProgress
            sx={{
              color: '#529BC5',
              marginRight: '15px'
            }}
            size={30}
            thickness={5.5}
          />
          {props.message}
        </Box>
      </Box>
    </>
  );
};

export const MainAreaProgress = (props: progressType) => {
  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      justifyContent: 'left',
      alignItems: 'left',
      flexDirection: 'column',
      '& > * + *': {
        //marginLeft: props.theme.spacing(2),
      },
      position: 'absolute',
      top: 250,
      marginTop: ' 2vw',
    }}>
      <Message>{props.message}</Message>
      <CircularProgress sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'left',
        alignItems: 'left',
        flexDirection: 'column',
        '& > * + *': {
          //marginLeft: props.theme.spacing(2),
        },
      }} size={100} />
    </Box>
  );
};

const Message = styled('div')`
  margin-top: 5vw;
  font-size: 24px;
`;