import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: string = 'test';

const testSlice = createSlice({
  name: 'test',
  initialState: initialState,
  reducers: {
    setTest: (_state, action: PayloadAction<string>) => action.payload,
  },
});

export const { setTest } = testSlice.actions;
export const testReducer = testSlice.reducer;
