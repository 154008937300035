/**
 * AWSDateTime文字列をmsに変換する
 * @param awsDateTimestring AWSDateTime文字列
 * @returns ミリ秒
 */
export const awsDateTime2MillsWithNull = (
  awsDateTimeString: string | null
) => {
  if (!awsDateTimeString) {
    return null;
  }
  return awsDateTime2Mills(awsDateTimeString)
};


/**
 * AWSDateTime文字列をmsに変換する
 * @param awsDateTimestring AWSDateTime文字列
 * @returns ミリ秒
 */
export const awsDateTime2Mills = (
  awsDateTimeString: string
) => {
  const date = new Date(awsDateTimeString.slice(0, -1));
  return date.getTime();
};


export enum TargetHostEnum {
  ADMIN = "admin",
  CLINIC = "clinic"
}

export enum AdminRolesTypeEnum {
  SUPERADMIN = "superAdmin",
  ADMIN = "admin",
  ANONYMOUS = "anonymous"
}

export enum ClinicRolesTypeEnum {
  OWNER = "owner",
  MANAGER = "manager",
  DOCTOR = "doctor",
  COMEDICAL = "coMedical",
  STAFF = "staff"
}

export enum AnalyzationStatus {
  WAITING_FOR_ANALYZE = 'waitingForAnalyze',
  WAITING_FOR_RE_CALCULATING = 'waitingForReCalculating',
  STARTING = 'starting',
  ANALYZING = 'analyzing',
  WAITING_FOR_CALCULATING = 'waitingForCalculating',
  CALCULATING = 'calculating',
  WAITING_FOR_CONFIRM = 'waitingForConfirm',
  WAITING_FOR_CONFIRM_REPORT = 'waitingForConfirmReport',
  REQUEST_FOR_RE_MEASURE = 'requestForReMeasure',
  FAILED = 'failed',
  CONFIRMED = 'confirmed',
}

export const dummyPhoneNumer = '+819012345678';

export const moduleDimension205 = '210520-205';
export const moduleDimension258 = '231006-258';
export const moduleDimension296 = '230828-296';