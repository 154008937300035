import { ReactNode } from 'react';
import { styled } from '@mui/system';

type Props = {
  children: ReactNode;
};

export const PageLayout = ({ children }: Props) => {
  return (
    <Container>
      <Contents>{children}</Contents>
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
});


const Contents = styled('div')({
  margin: '24px 36px 36px 36px',
  width: 'calc(100% - 72px)',
 // maxWidth: '900px',
  boxSizing: 'border-box',
});
