import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SidebarMenuButton } from '../molecules/SidebarMenuButton';
import {
  HospitalIcon,
  UserIcon,
  SearchIcon,
  SettingsIcon,
  PaperIcon,
  AnalysisIcon,
} from '../atoms/icons';
import Box from '@mui/material/Box';
import { BellIcon } from '../atoms/icons/BellIcon';
import { AnalysisInfoIcon } from '../atoms/icons/AnalysisInfoIcon';
import useLocalStorage from '../../hooks/localstorage';

const dataMenus = [
  // {
  //   title: 'ダッシュボード',
  //   path: '/',
  //   icon: <AnalysisIcon />,
  // },
  {
    title: '解析レポート検索/一覧',
    path: '/analyzations',
    icon: <AnalysisIcon />,
  },
];

const managementMenus = [
  {
    title: '医療機関',
    path: '/clinics',
    icon: <HospitalIcon />,
  },
  {
    title: '医療機関ユーザー',
    path: '/clinicUsers',
    icon: <UserIcon />,
  },
  {
    title: '解析プログラム',
    path: '/modules',
    icon: <PaperIcon />,
  },
  {
    title: '機器貸出管理',
    path: '/equipments',
    icon: <SettingsIcon />,
  },
  {
    title: '集計',
    path: '/analyzation-count',
    icon: <PaperIcon />,
  },
  {
    title: '提携先専門医',
    path: '/medicalPartners',
    icon: <SearchIcon />,
  },
  {
    title: 'スリープウェルユーザー',
    path: '/admins',
    icon: <UserIcon />,
  },
];

const settingMenus = [
  {
    title: 'リジェクト条件設定',
    path: '/adminSettings',
    icon: <SettingsIcon />,
  },
  {
    title: '顧客へのお知らせ設定',
    path: '/announcements',
    icon: <BellIcon />,
  },
  {
    title: 'マニュアル管理',
    path: '/documents',
    icon: <PaperIcon />,
  },
];

const anonymousMenus = [
  {
    title: '匿名加工情報一覧',
    path: '/',
    icon: <AnalysisInfoIcon />,
  },
];

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [group] = useLocalStorage('group', '');

  return (
    <Container>
      {group === 'anonymous' || group === ''
      ?
        (
          <StyledAccordion
            defaultExpanded={dataMenus.some(({ path }) => location.pathname.includes(path))}
          >
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>データ確認</StyledAccordionSummary>
            <StyledAccordionDetails>
              <Box>
                {anonymousMenus.map(({ icon, title, path }) => {
                  return (
                    <SidebarMenuButton
                      isActive={location.pathname.includes(path)}
                      icon={icon}
                      onClick={() => navigate(path)}
                    >
                      {title}
                    </SidebarMenuButton>
                  );
                })}
              </Box>
            </StyledAccordionDetails>
          </StyledAccordion>
        )
      :
        (
          <>
            <StyledAccordion
              defaultExpanded={dataMenus.some(({ path }) => {
                const p = location.pathname.slice(1);
                if (p === '') {
                  return true;
                }
                return location.pathname.includes(path)
              })}
            >
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>データ確認</StyledAccordionSummary>
              <StyledAccordionDetails>
                <Box>
                  <SidebarMenuButton
                    isActive={location.pathname.slice(1) === ''}
                    icon={<AnalysisIcon/>}
                    onClick={() => navigate('/')}
                  >
                    ダッシュボード
                  </SidebarMenuButton>
                  {dataMenus.map(({ icon, title, path }) => {
                    return (
                      <SidebarMenuButton
                        isActive={location.pathname.includes(path)}
                        icon={icon}
                        onClick={() => navigate(path)}
                      >
                        {title}
                      </SidebarMenuButton>
                    );
                  })}
                </Box>
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion
              defaultExpanded={managementMenus.some(({ path }) => location.pathname.includes(path))}
            >
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>登録・管理</StyledAccordionSummary>
              <StyledAccordionDetails>
                <Box>
                  {managementMenus.map(({ icon, title, path }) => {
                    return (
                      <SidebarMenuButton
                        isActive={location.pathname.includes(path)}
                        icon={icon}
                        onClick={() => navigate(path)}
                      >
                        {title}
                      </SidebarMenuButton>
                    );
                  })}
                </Box>
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion
              defaultExpanded={settingMenus.some(({ path }) => location.pathname.includes(path))}
            >
              <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>設定・出力</StyledAccordionSummary>
              <StyledAccordionDetails>
                <Box>
                  {settingMenus.map(({ icon, title, path }) => {
                    return (
                      <SidebarMenuButton
                        isActive={location.pathname.includes(path)}
                        icon={icon}
                        onClick={() => navigate(path)}
                      >
                        {title}
                      </SidebarMenuButton>
                    );
                  })}
                </Box>
              </StyledAccordionDetails>
            </StyledAccordion>
          </>
        )
      }
    </Container>
  );
};

const StyledAccordion = styled(Accordion)({
  border: 'none',
  boxShadow: 'none',
  backgroundColor: '#f2f2f2',
  '::before': {
    height: 0,
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  border: 'none',
  fontSize: '15px',
  paddingLeft: '10px',
  paddingRight: '10px',
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
});

const Container = styled('div')({
  backgroundColor: '#f2f2f2',
  width: '212px',
  height: '100%',
  paddingTop: '24px',
  boxSizing: 'border-box',
});
