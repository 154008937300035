import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: string = '';

const idTokenSlice = createSlice({
  name: 'idToken',
  initialState: initialState,
  reducers: {
    setIdToken: (_state, action: PayloadAction<string>) => action.payload,
    resetIdToken: () => '',
  },
});

export const { setIdToken, resetIdToken } = idTokenSlice.actions;

export const idTokenReducer = idTokenSlice.reducer;
