export const UserIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.6453 13.3857C16.7447 12.4684 15.6265 11.7716 14.3928 11.3577C14.7745 11.142 15.138 10.8621 15.4586 10.5356C16.3878 9.58915 16.8992 8.3298 16.8992 6.98785C16.8992 5.66242 16.3878 4.41182 15.4586 3.46633C14.5293 2.52084 13.3014 2 12 2C10.6834 2 9.44591 2.52084 8.51759 3.4673C7.58831 4.41376 7.07692 5.66437 7.07692 6.98882C7.07692 8.33077 7.58831 9.59013 8.51759 10.5366C8.83816 10.8631 9.20167 11.1429 9.5833 11.3587C8.34967 11.7726 7.23148 12.4684 6.33083 13.3866C4.82719 14.9171 4 16.9587 4 19.1354V20.0935C4 20.5793 4.18032 21.0594 4.49517 21.4092L4.5133 21.4286L4.56482 21.4549C4.89398 21.8066 5.35766 22 5.87191 22H18.1281C18.6252 22 19.0946 21.8008 19.4495 21.4393C19.7996 21.0827 20 20.5929 20 20.0935V19.1354C20.001 16.9752 19.1642 14.9336 17.6453 13.3857ZM12 12.3401C13.7651 12.3401 15.4366 13.0514 16.7046 14.3438C17.9726 15.6352 18.6719 17.3377 18.6719 19.1354V20.0935C18.6719 20.249 18.6223 20.3685 18.5107 20.4822C18.4095 20.5852 18.2664 20.6464 18.129 20.6464H5.87191C5.72117 20.6464 5.6 20.5939 5.49028 20.4822L5.46452 20.4559C5.39678 20.3869 5.32809 20.248 5.32809 20.0925V19.1344C5.32809 17.3095 6.02648 15.608 7.29636 14.3419C8.53858 13.0514 10.2092 12.3401 12 12.3401ZM9.45927 9.58138C8.77996 8.86328 8.40596 7.94209 8.40596 6.98882C8.40596 6.03945 8.78951 5.10465 9.45736 4.42445C10.1453 3.72384 11.0249 3.35361 12.001 3.35361C12.9579 3.35361 13.8748 3.74327 14.5169 4.42251L14.5207 4.42639C15.1876 5.08036 15.5702 6.01419 15.5702 6.98882C15.5702 7.9829 15.2067 8.87883 14.5188 9.57944C13.8509 10.2596 12.9331 10.6503 12.001 10.6503C11.065 10.6503 10.1605 10.2694 9.45927 9.58138Z'
        fill='#4B4B4B'
      />
    </svg>
  );
};
