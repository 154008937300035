import { ReactNode } from 'react';
import { styled } from '@mui/system';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { PageLayout } from '../atoms/PageLayout';
import Drawer from '@mui/material/Drawer';

type Props = {
  username: string;
  children: ReactNode;
};

export const PageFrame = ({ username, children }: Props) => {
  return (
    <>
      <HeaderContainer>
        <Header username={username} />
      </HeaderContainer>
      <Content>
        <Drawer
          variant="persistent"
          anchor="left"
          open={true}
          style={{zIndex: 99}}
        >
        <SidebarContaner>
          <Sidebar />
        </SidebarContaner>
        </Drawer>
        <MainContainer>
          <PageLayout>{children}</PageLayout>
        </MainContainer>
      </Content>
    </>
  );
};

const Content = styled('div')({
  display: 'flex',
});

const HeaderContainer = styled('div')({
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100%',
  zIndex: 100,
});

const SidebarContaner = styled('div')({
  marginTop: '72px',
  // height: '100%',
  minHeight: 'calc(100vh - 72px)',
});

const MainContainer = styled('div')({
  marginTop: '72px',
  marginLeft: '212px',
  minWidth: 'calc(100vw - 300px)',
  width: '100%',
});
